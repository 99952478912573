<template>
    <div>
        <h2 >新建渠道活码     <el-link   style="margin-left: 100px" @click="back" type="primary" >返回上一页</el-link> </h2>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

          <el-form-item label="活码分组" prop="grouptId ">
            <el-select v-model="ruleForm.grouptId" placeholder="请选择">
              <el-option
                  v-for="item in groupData"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

            <el-form-item label="活码名称" prop="liveCodeName">
                <el-input v-model="ruleForm.liveCodeName" placeholder="请输入活码名称" style="width: 50%"></el-input>
            </el-form-item>

            <el-form-item label="使用员工" prop="userIds">
                <commonSelect v-model="ruleForm.userIds" @getUserName="getUserName" mode="选择员工"/>
            </el-form-item>

            <el-form-item label="企微标签" prop="">
                <commonSelect v-model="ruleForm.tagName" mode="选择标签"/>
            </el-form-item>


            <el-form-item label="好友验证" prop="isClose">
                <el-radio-group v-model="ruleForm.isClose">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="欢迎语设置" prop="isPush">
                <el-radio-group v-model="ruleForm.isPush">
                    <el-radio :label="1">自定义活码</el-radio>
                    <el-radio :label="0">不推送</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item v-if="ruleForm.isPush" label="欢迎语内容" prop="pushText">
                <div style="display: flex;flex-direction: column">
                    <div>
                        <el-button type="primary" size="small" @click="dialogVisible = true">选择历史欢迎语</el-button>
                    </div>

                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 4, maxRows: 10}"
                            placeholder="请输入内容"
                            style="width: 70%"
                            v-model="ruleForm.pushText">
                    </el-input>
                </div>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                <el-button @click="back">取消</el-button>
            </el-form-item>


            <el-dialog
                    title="历史欢迎语"
                    :visible.sync="dialogVisible"
                    width="60%"
                    @open="openDialog">

                <div style="display: flex;flex-direction: row;">
                    <el-input v-model="historySearchVal" clearable style="margin-right: 20px"/>
                </div>

                <div style="margin-top: 10px">
                    <div style="border: 1px solid #eeeeee;padding: 10px;margin-bottom: 10px;cursor: pointer;"
                         v-for="item in historyWelcomeDataFilter" :key="item.id" @click="selectWelcome(item)">{{
                        item.pushText }}
                    </div>
                </div>
            </el-dialog>

        </el-form>

    </div>

</template>
<script>
    import commonSelect from "@/components/commonSelect/index.vue";
    import {generateQRCode, getWelcomeList, loveCodeGrouplist, workGetBYid} from "@/api/crm/LiveCode";
    import {left, right} from "core-js/internals/array-reduce";


    export default {
        components: {
            commonSelect
        },
        data() {
            return {
                dialogVisible: false,
                ruleForm: {
                    liveCodeName: '',
                    isClose: 1,
                    isPush: 1,
                    pushText: '',
                    grouptId: null
                },
                rules: {
                    grouptId: [{required: true, message: '请选择活码分组', trigger: 'change'}],
                    liveCodeName: [
                        {required: true, message: '请输入活码名称', trigger: 'blur'},
                        {min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur'}
                    ],
                    userIds: [
                        {required: true, message: '请选择使用员工', trigger: 'input'}
                    ],
                    isClose: [
                        {required: true, message: '请选择好友验证', trigger: 'change'}
                    ],
                    isPush: [
                        {required: true, message: '请选择欢迎语设置', trigger: 'change'}
                    ],
                    pushText: [
                        {required: true, message: '请输入欢迎语内容', trigger: 'input'}
                    ]


                },
                groupData: [],


                historyWelcomeData: [],
                historySearchVal: '',


                defaultProps: {
                    children: 'children',
                    label: 'label',
                    value: 'id',
                },
            };
        },
        computed: {
            historyWelcomeDataFilter() {
                return this.historyWelcomeData.filter(item => {
                    return item.pushText.includes(this.historySearchVal)
                })
            }
        },
        mounted() {
            this.getLiveCodeList()
            if (this.$route.query.id) {
                workGetBYid({
                    id: this.$route.query.id
                }).then(res => {
                    console.log(res)
                    this.ruleForm = res.data
                })
            }
        },
        methods: {
          right,
          left,
            changeOption(val) {
              console.log(val)
            },
            getUserName(_userName) {
                this.ruleForm.userName = _userName;
            },
            back() {
                this.$router.back()
            },
            selectWelcome(item) {
                this.ruleForm.pushText = item.pushText
                this.dialogVisible = false
            },
            openDialog() {
                getWelcomeList().then(res => {

                    this.historyWelcomeData = res.data
                })
            },
            getLiveCodeList() {
                loveCodeGrouplist().then(res => {
                    const arr = res.data || []
                    this.groupData = arr
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        if (this.$route.query.id) {
                            this.ruleForm.id = this.$route.query.id
                        }

                        generateQRCode(this.ruleForm).then(res => {
                            if (res.code === 200) {
                                if (this.ruleForm.id) {
                                    this.$message.success('编辑成功')
                                }else{
                                    this.$message.success('创建成功')
                                }

                                this.$router.back()
                            }else{
                                this.$message.error(res.msg)
                            }
                        })

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
    };
</script>
